import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faGlobeAmericas, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { CardContents, channelTypeMap, Channel } from './data'
import { Form, FormElement, TextInput } from "../FormElement";
import useDebounce from '../Common/useDebounce';
import Fuse from 'fuse.js'

library.add(
	fab,
	faGlobeAmericas,
	faEnvelope,
  )

const HuobiBanner = styled.div<{imageUrl: string }>`
	${ props => 'background-image: url(/img/' + props.imageUrl + '.png)' };
	background-size: 300px;
	height: 75px;
	background-repeat: no-repeat;
`

const Certified = styled.img<{delisted?: boolean | 'pending', isFocused: boolean}>`
  ${ props => !props.delisted && 'display: none' };
  ${ props => props.isFocused && 'opacity: 0.25;' }
  position: absolute;
  right: 0;
  height: 100px;
  width: 100px;
`

// ${ props => `${props.delisted && 
// 	'filter: grayscale(0.5); opacity: 0.25;'}`}

const HoubiH2 = styled.h2<{banner?: string}>`
	margin: 0;
	padding: 1rem 1.5rem 1.25rem 1.5rem;
	background-color: black;
	color: white;
	font-weight: normal;
	font-size: 2rem;
	${ props => `${props.banner && 'display: none;'}` }
`
  
const Content = styled.div`
    margin: 2rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
    max-width: 64rem;
    justify-content: center;
`

const CardContainer = styled.div<{border?: boolean}>`
    border: ${ props => (props.border ?? true) ? 1 : 0}px solid #9E9E9E;
    border-radius: 0.25rem;
    width: 18.75rem;
    margin: 1rem;
	padding: 0 0.0rem 0 0rem;
	ul {
		margin: 1.125rem 0rem 1.125rem 1.5rem;
		padding-left: 0rem;
		list-style-type: none;
		display: flex;
		flex-directoin: row;
		flex-wrap: wrap;
		li {
			width: 130px;
			font-size: 1rem;
			color: #343a40;
			margin-top: 0.5rem;
			a, a:visited, a:hover, a:active {
				text-decoration:none;
				color: #343a40;
			};
			a:hover { 
				color: #666666;
				text-decoration: underline;
			}
			svg { margin-right: 0.5rem };
		}
	};
`

// ${props => props.disabled && 'pointer-events: none; color: #cccccc;'}

const CardContent = styled.div`
	padding: 0rem 0 0rem 0rem;
	position: relative;
`

export interface CardContentComponentProps {
	banner?: string,
	description: string,
	channels: Channel[],
	delisted?: boolean | 'pending',
}

const CardContentComponent: FC<CardContentComponentProps> = (card) => {
	const [isCardFocused, setIsCardFocused] = useState(false)

	const cardHoverHandler = useCallback( () => {
		setIsCardFocused(true)
	},[setIsCardFocused])

	const cardMouseLeaveHandler = useCallback( () => {
		setIsCardFocused(false)
	}, [setIsCardFocused])

	return (<CardContent onMouseEnter={cardHoverHandler} onMouseLeave={cardMouseLeaveHandler}>

		<Certified isFocused={isCardFocused} src={ 
				card.delisted === true ? "/img/bsvfree_orange.png" :
				card.delisted === 'pending' ? '/img/pending.png' : 
				''

			}
			delisted={card.delisted}/>

		<HuobiBanner role="banner" title={card.description} imageUrl={card.banner ?? '' }>
			<HoubiH2 banner={card?.banner}>{card.description}</HoubiH2>
		</HuobiBanner>

		<ul>
		{ card.channels.map(channel => 
			(<li>
				<a href={channel.url}>
				<FontAwesomeIcon icon={[channelTypeMap[channel.channelType].set, channelTypeMap[channel.channelType].icon]} size="1x" />{channelTypeMap[channel.channelType].description}</a>
			</li>)
		)}
		</ul>

	</CardContent>)
}

const fuse = new Fuse(CardContents, {
	keys: ['description'],
	threshold: 0.2
})

const FormContent = styled(Content)`
	max-width: 60.75rem;
	
	@media only screen and (max-width: 1024px) and (min-width: 670px) {
		max-width: 40rem;
	}

	@media only screen and (max-width: 669px) {
		max-width: 19rem;
	}
`

const CardFilterFormElement = styled(FormElement)`
	width: 100%;
`

type sortType = 'alpha' | 'volume' | 'delisted'

export const BSV: FC = () => {

	const [searchTerm, setSearchTerm] = useState('')
	const [sortValue, setSortValue] = useState<sortType>('volume')

	const [cardContents, setCardContents] = useState(CardContents)

	const debouncedSarchTerm = useDebounce(searchTerm, 500)

	useEffect(() => {
		if(debouncedSarchTerm){
			setCardContents(fuse.search(debouncedSarchTerm).map(s => s.item))
		}
		else{
			setCardContents(CardContents)
		}
	}, [debouncedSarchTerm])

	return (

		<>
			<FormContent>
				<CardFilterFormElement><label>Filter: <TextInput placeholder="filter" onChange={e => setSearchTerm(e.target.value)} /></label></CardFilterFormElement>
				{ /*<CardFilterFormElement><label>Sort by: </label>
					<select value="volume">
						<option value="volume">Volume</option>
						<option value="alpha">Name</option>
						<option value="delisted">Delisted</option>	
					</select>
				</CardFilterFormElement> */ }
			</FormContent>

			<Content>
			
				{ cardContents.map(card => 
				
					(<CardContainer>
						<CardContentComponent {...card} />
					</CardContainer>)
				)}
				<CardContainer border={false} />
				<CardContainer border={false} />
				
			</Content >
		</>)
}
