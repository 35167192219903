import { IconName, IconPrefix } from "@fortawesome/free-solid-svg-icons";

export const channelTypeMap: { [channelName: string]: ChannelTypeMapping } = {
	'facebook': { description: 'Facebook', set: 'fab', icon: 'facebook-square', },
	'twitter': { description: 'Twitter', set: 'fab', icon: 'twitter-square', },
	'telegram': { description: 'Telegram', set: 'fab', icon: 'telegram', },
	'email': { description: 'Email', set: 'fas', icon: 'envelope', },
	'phone': { description: 'Phone', set: 'fas', icon: 'phone-square', },
	'linkedin': { description: 'LinkedIn', set: 'fab', icon: 'linkedin', },
	'web': { description: 'Web', set: 'fas', icon: 'globe-americas', },
	'instagram': { description: 'Instagram', set: 'fab', icon: 'instagram', },
	'weibo': { description: 'Weibo', set: 'fab', icon: 'weibo', },
	'youtube': { description: 'YouTube', set: 'fab', icon: 'youtube', },
}

export type ContactChannelType = 'facebook' | 'twitter' | 'telegram' | 'email' | 'phone' | 'linkedin' | 'web' | 'instagram' | 'wecaht' | 'weibo' | 'youtube'

export interface ChannelTypeMapping {
	description: string,
	set: IconPrefix,
	icon: IconName
}

export interface Channel {
	channelType: ContactChannelType,
	url: string,
}

export interface Card {
	banner?: string,
	type: 'exchange' | 'wallet',
	description: string,
	country?: string,
	channels: Channel[],
	delisted?: boolean | 'pending',
}

export const CardContents: Card[] = [
	{
		banner: 'bitfinex',
		type: 'exchange',
		description: 'Bitfinex',
		delisted: true,
		channels: [
			{ channelType: 'web', url: 'https://www.huobigroup.com/en-us/', }, 
			{ channelType: 'twitter', url: 'https://twitter.com/bitfinex', }, 
			{ channelType: 'facebook', url: 'https://www.facebook.com/bitfinex', }, 
			{ channelType: 'linkedin', url: 'https://www.linkedin.com/company/bitfinex/',}, 
			{ channelType: 'telegram', url: 'https://t.me/bitfinex', }, 
		]
	},
	{
		banner: 'bittrex',
		type: 'exchange',
		description: 'Bittrex',
		delisted: true,
		channels: [
			{ channelType: 'web', url: 'https://bittrexglobal.zendesk.com/hc/en-us/requests/new', }, 
			{ channelType: 'twitter', url: 'https://twitter.com/BittrexExchange', }, 
			{ channelType: 'facebook', url: 'https://www.facebook.com/BittrexGlobal/', }, 
		]
	},
	{
		banner: 'robinhood',
		type: 'exchange',
		country: 'United States',
		description: 'Robinhood',
		delisted: true,
		channels: [
			{ channelType: 'web', url: 'https://www.robinhood.com', }, 
			{ channelType: 'twitter', url: 'https://twitter.com/RobinhoodApp', }, 
			{ channelType: 'facebook', url: 'https://www.facebook.com/robinhoodapp', },
			// { channelType: 'telegram', url: '', },
			{ channelType: 'linkedin', url: 'https://www.linkedin.com/company/robinhood', },
			{ channelType: 'instagram', url: 'https://www.instagram.com/robinhoodapp', }, 
			// { channelType: 'weibo', url: '', }, 
			// { channelType: 'youtube', url: '', }, 
		]
	},
	{
		banner: 'bitoasis',
		type: 'exchange',
		delisted: true,
		description: 'BitOasis',
		channels: [
			{ channelType: 'web', url: 'https://bitoasis.net', }, 
			{ channelType: 'twitter', url: 'https://twitter.com/bitoasis', }, 
			{ channelType: 'facebook', url: 'https://www.facebook.com/bitoasis/', }, 
			{ channelType: 'telegram', url: 'https://t.me/BitOasisOfficial', },
			{ channelType: 'linkedin', url: 'https://www.linkedin.com/company/bitoasis-technologies-fze/', },
			{ channelType: 'instagram', url: 'https://www.instagram.com/bitoasismena/' }, 
		]
	},
	{
		banner: 'coinpaymentsnet',
		type: 'wallet',
		description: 'CoinPayments.NET',
		delisted: true,
		channels: [
			{ channelType: 'web', url: 'https://www.coinpayments.net/help-support', }, 
			{ channelType: 'twitter', url: 'https://twitter.com/CoinPaymentsNET', }, 
			{ channelType: 'facebook', url: 'https://www.facebook.com/CoinPayments', }, 
			{ channelType: 'telegram', url: 'https://t.me/coinpaymentsinc', }, 
			{ channelType: 'instagram', url: 'https://www.instagram.com/coinpayments/', }, 
			{ channelType: 'linkedin', url: 'https://www.linkedin.com/company/coinpayments-inc/', }, 
		]
	},
	{
		banner: 'okcoin',
		type: 'exchange',
		delisted: true,
		description: 'OKCoin',
		channels: [
			{ channelType: 'web', url: 'https://www.okcoin.com/', }, 
			{ channelType: 'email', url: 'mailto:support@okcoin.com'},
			{ channelType: 'twitter', url: 'https://twitter.com/OKCoin', }, 
			{ channelType: 'facebook', url: 'https://www.facebook.com/OKCoinOfficial', }, 
			{ channelType: 'telegram', url: 'https://t.me/okcoin_en', },
			{ channelType: 'linkedin', url: 'https://www.linkedin.com/company/okcoin', },
		]
	},
	{
		banner: 'coinsquare',
		country: 'Canada',
		type: 'exchange',
		description: 'Coinsquare',
		delisted: true,
		channels: [
			{ channelType: 'web', url: 'https://www.coinsquare.com', },
			{ channelType: 'email', url: 'mailto:support@coinsquare.com', }, 
			{ channelType: 'twitter', url: 'https://twitter.com/Coinsquare', }, 
		]
	},
	{
		banner: 'coinjar',
		type: 'exchange',
		country: 'Australia',
		description: 'CoinJar',
		delisted: true,
		channels: [
			{ channelType: 'web', url: 'https://www.coinjar.com/', }, 
			{ channelType: 'twitter', url: 'https://twitter.com/getcoinjar', }, 
			{ channelType: 'facebook', url: 'https://www.facebook.com/CoinJar/', },
			{ channelType: 'linkedin', url: 'https://www.linkedin.com/company/coinjar/', },
			{ channelType: 'youtube', url: 'http://www.youtube.com/c/Coinjar', }, 
		]
	},
	{
		banner: 'aax',
		type: 'exchange',
		delisted: true,
		country: '',
		description: 'AAX',
		channels: [
			{ channelType: 'web', url: 'https://www.aax.com/en-US/', }, 
			{ channelType: 'twitter', url: 'https://twitter.com/@AAXExchange', }, 
		]
	},
	{
		banner: 'independentreserve',
		delisted: true,
		type: 'exchange',
		country: 'Australia',
		description: 'Independent Reserve',
		channels: [
			{ channelType: 'web', url: 'https://www.independentreserve.com/', }, 
			{ channelType: 'twitter', url: 'https://twitter.com/indepreserve', }, 
			{ channelType: 'facebook', url: 'https://www.facebook.com/independentreserve', }, 
			{ channelType: 'linkedin', url: 'https://www.linkedin.com/company/independent-reserve', },
		]
	},
	{
		banner: 'southxchange',
		delisted: true,
		type: 'exchange',
		country: 'argentina',
		description: 'SouthXchange',
		channels: [
			{ channelType: 'web', url: 'https://southxchange.freshdesk.com/', }, 
			{ channelType: 'twitter', url: 'https://twitter.com/southxchange', }, 
			{ channelType: 'facebook', url: 'https://www.facebook.com/southxchange', }, 
			{ channelType: 'telegram', url: 'https://t.me/SouthXchangeEng', },
		]
	},
	{
		banner: 'wavesexchange',
		delisted: true,
		type: 'exchange',
		country: 'none',
		description: 'Waves Exchange',
		channels: [
			{ channelType: 'web', url: 'https://waves.exchange', }, 
			{ channelType: 'twitter', url: 'https://twitter.com/Waves_Exchange', }, 
			{ channelType: 'telegram', url: 'https://t.me/wavesexchange_announcements', },
		]
	},
	{
		banner: 'bitkub',
		type: 'exchange',
		country: 'Thailand',
		description: 'bitkub',
		delisted: true,
		channels: [
			{ channelType: 'web', url: 'https://www.bitkub.com/', }, 
			{ channelType: 'twitter', url: 'https://www.twitter.com/bitkubofficial', }, 
			{ channelType: 'facebook', url: 'https://www.facebook.com/bitkubofficial', },
			{ channelType: 'telegram', url: 'https://t.me/bitkubofficial', },
			{ channelType: 'linkedin', url: 'https://www.linkedin.com/company/bitkub/', },
			{ channelType: 'instagram', url: 'https://www.instagram.com/bitkubofficial', }, 
			{ channelType: 'youtube', url: 'https://www.youtube.com/c/BitkubOfficial', }, 
		]
	},
	{
		banner: 'youhodler',
		delisted: true,
		type: 'exchange',
		country: 'Switzerland',
		description: 'YouHodler',
		channels: [
			{ channelType: 'web', url: 'https://www.youhodler.com/', }, 
			{ channelType: 'twitter', url: 'https://twitter.com/youhodler', }, 
			{ channelType: 'facebook', url: 'https://www.facebook.com/YouHodler/', }, 
			{ channelType: 'instagram', url: 'https://www.instagram.com/youhodler/', },
			{ channelType: 'linkedin', url: 'https://www.linkedin.com/company/youhodler', },
			{ channelType: 'youtube', url: 'https://www.youtube.com/channel/UC-Kxvqm8Q-l_WGxQL0VpEEQ', },
		]
	},
	{
		banner: 'huobi',
		type: 'exchange',
		description: 'Huobi',
		channels: [
			{ channelType: 'web', url: 'https://www.huobigroup.com/en-us/', }, 
			{ channelType: 'twitter', url: 'https://twitter.com/HuobiGlobal', }, 
			{ channelType: 'facebook', url: 'https://www.facebook.com/huobiglobalofficial', }, 
			{ channelType: 'youtube', url: 'https://www.youtube.com/HuobiGlobal',}, 
			{ channelType: 'telegram', url: 'https://t.me/huobiglobalofficial', }, 
			{ channelType: 'instagram', url: 'https://www.instagram.com/huobiglobalofficial', }, 
			{ channelType: 'weibo', url: 'https://weibo.com/p/1006063495498135/home?from=page_100606&mod=TAB&is_all=1#place', },
		]
	},
	{
		banner: 'netcoins',
		country: 'Canada',
		type: 'exchange',
		description: 'Netcoins',
		delisted: true,
		channels: [
			{ channelType: 'web', url: 'https://netcoins.ca/', },
			{ channelType: 'email', url: 'support@netcoins.ca', }, 
			{ channelType: 'twitter', url: 'https://twitter.com/Netcoins', },
			{ channelType: 'facebook', url: 'https://www.facebook.com/goNetcoins/', }, 
			{ channelType: 'instagram', url: 'https://www.instagram.com/netcoins/' }, 
		]
	},
	{
		banner: 'btcmarkets',
		country: 'Australia',
		type: 'exchange',
		description: 'btcmarkets',
		channels: [
			{ channelType: 'web', url: 'https://support.btcmarkets.net/hc/en-us/requests/new', }, 
			{ channelType: 'twitter', url: 'https://twitter.com/BTCMarkets', }, 
			{ channelType: 'facebook', url: 'https://facebook.com/BTCMarkets', }, 
			{ channelType: 'telegram', url: 'https://t.me/BTCMkts', }, 		
			{ channelType: 'linkedin', url: 'https://linkedin.com/company/btc-markets', },
		]
	},

	{
		banner: 'coinspot',
		type: 'exchange',
		country: 'Australia',
		description: 'CoinSpot',
		channels: [
			{ channelType: 'web', url: 'https://coinspot.zendesk.com/hc/en-us/requests/new', }, 
			{ channelType: 'twitter', url: 'https://coinspot.zendesk.com/hc/en-us/requests/new', }, 
		]
	},
	{
		banner: 'digitalsurge',
		type: 'exchange',
		country: 'Australia',
		description: 'Digital Surge',
		delisted: true,
		channels: [
			{ channelType: 'web', url: 'https://digitalsurge.com.au'},
			{ channelType: 'twitter', url: 'https://twitter.com/DigitalSurge_AU', }, 
			{ channelType: 'facebook', url: 'https://www.facebook.com/DigitalSurgeAU', },
			{ channelType: 'telegram', url: 'https://t.me/digitalsurge', },
			{ channelType: 'linkedin', url: 'https://au.linkedin.com/company/digitalsurge', },
		]
	},
	{
		banner: 'hitbtc',
		type: 'exchange',
		description: 'HitBTC',
		channels: [
			{ channelType: 'web', url: 'https://hitbtc.com/', }, 
			{ channelType: 'twitter', url: 'https://twitter.com/hitbtc', }, 
			{ channelType: 'facebook', url: 'https://www.facebook.com/hitbtc', }, 
			{ channelType: 'telegram', url: 'https://t.me/EN_HitBTC', }, 
		]
	},
	{
		banner: 'okex',
		type: 'exchange',
		description: 'OKEx',
		channels: [
			{ channelType: 'web', url: 'https://www.okex.com/', }, 
			{ channelType: 'twitter', url: 'https://www.okex.com/community.html', }, 
			{ channelType: 'facebook', url: 'https://www.okex.com/community.html', }, 
			{ channelType: 'telegram', url: 'https://t.me/OKExOfficial_English', }, 
			{ channelType: 'weibo', url: 'https://weibo.com/okexcom', },
		]
	},
	{
		banner: 'bithumb',
		description: 'bithumb',
		type: 'exchange',
		channels: [
			{ channelType: 'email', url: 'info@bithumbcorp.com'},
			{ channelType: 'web', url: 'https://en.bithumb.com/', }, 
			{ channelType: 'twitter', url: 'https://twitter.com/BithumbOfficial', }, 
		]
	},
	{
		banner: 'upbit',
		type: 'exchange',
		description: 'Upbit',
		delisted: true,
		channels: [
			{ channelType: 'web', url: 'https://sg.upbit.com/home', }, 
			{ channelType: 'email', url: 'mailto:upbit_sg@upbit.com'},
			{ channelType: 'twitter', url: 'https://twitter.com/upbitglobal', }, 
			{ channelType: 'facebook', url: 'https://www.facebook.com/upbit.exchange', }, 
			{ channelType: 'linkedin', url: 'https://www.linkedin.com/company/upbit-official', },
			{ channelType: 'instagram', url: 'https://www.instagram.com/upbit.exchange', }, 
		]
	},
	{
		banner: 'indoex',
		type: 'exchange',
		description: 'IndoEx',
		channels: [
			{ channelType: 'web', url: 'https://international.indoex.io/contact', }, 
			{ channelType: 'email', url: 'mailto:listing@indoex.io', }, 
			{ channelType: 'twitter', url: 'https://twitter.com/Indoex_LTD', }, 
			{ channelType: 'facebook', url: 'https://www.facebook.com/Indoex.official/', }, 
			{ channelType: 'telegram', url: 't.me/indoexofficial', }, 
			{ channelType: 'instagram', url: 'https://www.instagram.com/indoex.io/', }, 
			{ channelType: 'linkedin', url: 'https://www.linkedin.com/company/15804956/admin/', },
		]
	},
	{
		banner: 'bitforex',
		type: 'exchange',
		description: 'BitForex',
		country: 'Hong Kong',
		channels: [
			{ channelType: 'web', url: '', }, 
			{ channelType: 'twitter', url: 'https://twitter.com/bitforexcom', }, 
			{ channelType: 'facebook', url: 'https://www.facebook.com/bitforexcom', }, 
			{ channelType: 'linkedin', url: 'https://www.linkedin.com/company/bitforexexchange/',}, 
			{ channelType: 'telegram', url: 'https://t.me/BitForexEnglish', }, 
			{ channelType: 'instagram', url: 'https://www.instagram.com/bitforex_official/', }, 
			{ channelType: 'youtube', url: 'https://www.youtube.com/channel/UCg2lgc1Cjw1t-pSpDN4DJGw', },
		]
	},
	{
		banner: 'coinbit',
		type: 'exchange',
		country: 'Korea',
		description: 'CoinBit',
		delisted: true,
		channels: [
			{ channelType: 'web', url: 'https://www.coinbit.co.kr/', }, 
			{ channelType: 'email', url: 'mailto:cs@coinbit.co.kr', },
			{ channelType: 'twitter', url: 'https://twitter.com/coinbit_coinbit', }, 
			{ channelType: 'telegram', url: 'https://t.me/CoinbitGlobal', }, 
			{ channelType: 'instagram', url: 'https://www.instagram.com/coinbit2.0/', }, 
		]
	},
	{
		banner: 'digifinex',
		type: 'exchange',
		description: 'DIGIFINEX',
		channels: [
			{ channelType: 'web', url: 'https://www.digifinex.com/en-ww/', },
			{ channelType: 'email', url: 'mailto:support@digifinex.com'}, 
			{ channelType: 'twitter', url: 'https://twitter.com/digifinex', }, 
			{ channelType: 'facebook', url: 'https://www.facebook.com/digifinex.global/', }, 
			{ channelType: 'linkedin', url: 'https://www.linkedin.com/company/digifinex-global/',}, 
			{ channelType: 'telegram', url: 'https://t.me/DigiFinexEN', }, 
			{ channelType: 'instagram', url: 'https://www.instagram.com/digifinex.global/', }, 
		]
	},
	{
		banner: 'coinex',
		type: 'exchange',
		description: 'CoinEx',
		channels: [
			{ channelType: 'web', url: 'https://support.coinex.com/hc/en-us/requests/new', }, 
			{ channelType: 'email', url: 'mailto:support@coinex.com', },
			{ channelType: 'twitter', url: 'https://twitter.com/coinexcom', }, 
			{ channelType: 'facebook', url: 'https://www.facebook.com/TheCoinEx/', }, 
			{ channelType: 'telegram', url: 'https://t.me/CoinExOfficialENG', }, 
			{ channelType: 'weibo', url: 'https://weibo.com/coinex', },
		]
	},
	{
		banner: 'mxc',
		type: 'exchange',
		description: 'mxc.com',
		channels: [
			{ channelType: 'web', url: '', }, 
			{ channelType: 'email', url: 'suggest@mxc.com', }, 
			{ channelType: 'twitter', url: 'https://twitter.com/MXC_Exchange', }, 
			{ channelType: 'facebook', url: 'https://www.facebook.com/mxcexchangeofficial/', },
			{ channelType: 'linkedin', url: 'https://www.linkedin.com/company/mxc-exchange', }, 
			{ channelType: 'telegram', url: 'https://t.me/MXCEnglish', }, 
			{ channelType: 'weibo', url: 'https://m.weibo.cn/u/6605413404', },
		]
	},
	{
		banner: 'finexbox',
		type: 'exchange',
		description: 'FinexBox',
		channels: [
			{ channelType: 'web', url: 'https://www.finexbox.com/?l=en-us', }, 
			{ channelType: 'twitter', url: 'https://twitter.com/finexbox', }, 
		]
	},
	{
		banner: 'gateio',
		type: 'exchange',
		description: 'gate.io',
		channels: [
			{ channelType: 'web', url: 'https://www.gate.io/', }, 
			{ channelType: 'email', url: 'mailto:support@mail.gate.io', }, 
			{ channelType: 'twitter', url: 'https://twitter.com/gate_io', }, 
			{ channelType: 'telegram', url: 'https://t.me/gateio', }, 
			{ channelType: 'instagram', url: 'https://instagram.com/gateioglobal', }, 
		]
	},
	{
		banner: 'poloniex',
		type: 'exchange',
		country: '',
		description: 'Poloniex',
		channels: [
			{ channelType: 'web', url: 'https://support.poloniex.com/hc/en-us/requests/new', }, 
			{ channelType: 'twitter', url: 'https://twitter.com/Poloniex', }, 
			{ channelType: 'telegram', url: 'https://t.me/poloniexenglish', },
			{ channelType: 'weibo', url: 'https://www.weibo.com/u/7335432157', }, 
		]
	},
	{
		banner: 'zbg',
		type: 'exchange',
		country: '',
		description: 'ZBG',
		channels: [
			{ channelType: 'web', url: 'https://www.zbg.com/', }, 
			{ channelType: 'twitter', url: 'https://twitter.com/ZBGLOBLE', }, 
			{ channelType: 'facebook', url: 'https://www.facebook.com/zbg.rus.35', },
			{ channelType: 'telegram', url: 'https://t.me/ZBGofficial', },
			{ channelType: 'weibo', url: 'https://weibo.com/6592874556/', }, 
		]
	},
	{
		banner: 'paybito',
		type: 'exchange',
		country: '',
		description: 'PayBito',
		channels: [
			{ channelType: 'web', url: 'https://www.paybito.com/contact-us/', }, 
			{ channelType: 'twitter', url: 'https://twitter.com/paybito', }, 
			{ channelType: 'facebook', url: 'https://www.facebook.com/paybito/', },
			{ channelType: 'linkedin', url: 'https://www.linkedin.com/company/paybito', },
			{ channelType: 'instagram', url: 'https://t.me/paybito_official', }, 
		]
	},
	{
		banner: 'bitvavo',
		type: 'exchange',
		country: 'The Netherlands',
		description: 'Bitvavo',
		delisted: true,
		channels: [
			{ channelType: 'web', url: 'https://bitvavo.com/en', }, 
			{ channelType: 'email', url: 'support@bitvavo.com'},
			{ channelType: 'twitter', url: 'https://twitter.com/bitvavocom', }, 
		]
	},
	{
		banner: 'bibox',
		type: 'exchange',
		country: '',
		description: 'Bibox',
		channels: [
			{ channelType: 'web', url: 'https://www.bibox.com/en', }, 
			{ channelType: 'twitter', url: 'http://www.twitter.com/Bibox365', }, 
			{ channelType: 'facebook', url: 'https://www.facebook.com/BiboxGlobal', },
			{ channelType: 'telegram', url: 'https://t.me/BiboxExchangeEnglish', },
			{ channelType: 'linkedin', url: 'https://www.linkedin.com/company/14405898', },
			{ channelType: 'weibo', url: 'https://weibo.com/u/6587962025', }, 
		]
	},
	{
		banner: 'whitebit',
		type: 'exchange',
		country: '',
		description: 'WhiteBIT',
		delisted: true,
		channels: [
			{ channelType: 'web', url: 'https://whitebit.com/', }, 
			{ channelType: 'email', url: 'listing@whitebit.com '},
			{ channelType: 'twitter', url: 'https://twitter.com/WhiteBit6', }, 
			{ channelType: 'facebook', url: 'https://www.facebook.com/Whitebit-2208080676073343/', },
			{ channelType: 'telegram', url: 'https://t.me/White_Bit', },
			{ channelType: 'linkedin', url: 'https://www.linkedin.com/company/whitebit-cryptocurrency-exchange/', },
		]
	},
	{
		banner: 'cointiger',
		type: 'exchange',
		country: '',
		description: 'CoinTiger',
		channels: [
			{ channelType: 'web', url: 'https://www.cointiger.com/en-us', }, 
			{ channelType: 'email', url: 'listing_en@cointiger.com'},
			{ channelType: 'twitter', url: 'https://twitter.com/CoinTigerEX', }, 
			{ channelType: 'facebook', url: 'https://www.facebook.com/CoinTigerEX', },
			{ channelType: 'telegram', url: 'https://t.me/CoinTiger_Listing', },
			{ channelType: 'weibo', url: 'https://weibo.com/CoinTiger', }, 
		]
	},
	{
		banner: 'bigone',
		type: 'exchange',
		country: '',
		description: 'BigONE',
		channels: [
			{ channelType: 'web', url: 'https://support.bigonechina.com/hc/en-us/requests/new', }, 
			{ channelType: 'twitter', url: 'https://twitter.com/BigONEexchange', }, 
			{ channelType: 'facebook', url: 'https://www.facebook.com/BigONEKoreaOfficial', },
			{ channelType: 'telegram', url: 'https://t.me/B1_EN', },
		]
	},
	{
		banner: 'bitbay',
		type: 'exchange',
		country: '',
		description: 'BitBay',
		channels: [
			{ channelType: 'web', url: 'https://bitbay.net/en', }, 
			{ channelType: 'email', url: 'listing@bitbay.net'},
			{ channelType: 'twitter', url: 'https://twitter.com/BitBay/', }, 
			{ channelType: 'facebook', url: 'https://www.facebook.com/BitBay/', },
			{ channelType: 'telegram', url: 'https://t.me/BitBayBitcoin', },
			{ channelType: 'linkedin', url: 'https://pl.linkedin.com/company/bitbay', },
			{ channelType: 'instagram', url: 'https://instagram.com/bitbay', }, 
			{ channelType: 'youtube', url: 'https://www.youtube.com/channel/UCo1PJlqUMGYfCnZHX0mDONQ', }, 
		]
	},
	{
		banner: 'wazirx',
		type: 'exchange',
		country: 'India',
		description: 'WazirX',
		channels: [
			{ channelType: 'web', url: 'https://support.wazirx.com/hc/en-us/requests/new', }, 
			{ channelType: 'twitter', url: 'http://twitter.com/WazirxIndia', }, 
			{ channelType: 'facebook', url: 'http://facebook.com/wazirx', },
			{ channelType: 'telegram', url: 'https://t.me/wazirx', },
			{ channelType: 'linkedin', url: 'https://www.linkedin.com/company/wazirx/', },
			{ channelType: 'instagram', url: 'https://www.instagram.com/wazirx/', }, 
			{ channelType: 'youtube', url: 'https://www.youtube.com/wazirx', }, 
		]
	},
	{
		banner: 'novadax',
		type: 'exchange',
		country: '',
		description: 'NovaDAX',
		channels: [
			{ channelType: 'web', url: 'https://novadax.zendesk.com/hc/en-us/requests/new', }, 
			{ channelType: 'twitter', url: 'https://twitter.com/nova_dax', }, 
			{ channelType: 'facebook', url: 'https://www.facebook.com/OfficialNovaDAX', },
			{ channelType: 'telegram', url: 'https://t.me/NovaDAX_BR', },
			{ channelType: 'youtube', url: 'https://www.youtube.com/channel/UC9Ryk9o7HXyPe6bZMc7SedQ/featured', }, 
		]
	},
	{
		banner: 'hotbit',
		type: 'exchange',
		country: '',
		description: 'hotbit',
		channels: [
			{ channelType: 'web', url: 'https://hotbit.pro', }, 
			{ channelType: 'twitter', url: 'https://twitter.com/Hotbit_news', }, 
			{ channelType: 'facebook', url: 'https://www.facebook.com/hotbitexchange', },
			{ channelType: 'telegram', url: 'https://t.me/Hotbit_English', },
		]
	},
	{
		banner: 'bitbns',
		type: 'exchange',
		country: 'India',
		description: 'Bitbns',
		channels: [
			{ channelType: 'web', url: 'https://bitbns.freshdesk.com/support/home', }, 
			{ channelType: 'twitter', url: 'https://twitter.com/bitbns/', }, 
			{ channelType: 'facebook', url: 'https://www.facebook.com/bitbns/', },
			{ channelType: 'telegram', url: 'https://t.me/Bitbns', },
			{ channelType: 'linkedin', url: 'https://www.linkedin.com/company/bitbnsinc/', },
			{ channelType: 'instagram', url: 'https://www.instagram.com/bitbns/', }, 
			{ channelType: 'youtube', url: 'https://www.youtube.com/c/Bitbns?sub_confirmation=1', }, 
		]
	},
	{
		banner: 'coindcx',
		type: 'exchange',
		country: 'India',
		description: 'CoinDCX',
		channels: [
			{ channelType: 'web', url: 'https://coindcx.com/#!', }, 
			{ channelType: 'email', url: 'support@coindcx.com'},
			{ channelType: 'twitter', url: 'https://twitter.com/coindcx', }, 
			{ channelType: 'telegram', url: 'https://t.me/coindcx/', },
			{ channelType: 'instagram', url: 'https://www.instagram.com/coindcxofficial/', }, 
			{ channelType: 'youtube', url: 'https://www.youtube.com/channel/UC3tRLIiVt1HdFsfJQu-9rzA', }, 
		]
	},
	// {
	// 	banner: '',
	// 	type: '',
	// 	country: '',
	// 	description: '',
	// 	channels: [
	// 		// { channelType: 'web', url: '', }, 
	// 		// { channelType: 'email', url: ''},
	// 		// { channelType: 'twitter', url: '', }, 
	// 		// { channelType: 'facebook', url: '', },
	// 		// { channelType: 'telegram', url: '', },
	// 		// { channelType: 'linkedin', url: '', },
	// 		// { channelType: 'instagram', url: '', }, 
	// 		// { channelType: 'weibo', url: '', }, 
	// 		// { channelType: 'youtube', url: '', }, 
	// 	]
	// },
]
