import styled from "styled-components";

export const FormElement = styled.div`margin-top: 1rem;`;

export const Form = styled.form`
    margin: auto;
    & label {
        font-size: 1rem;
    }
    display: flex;
    flex-direction: column;
    align-item: center;
    max-width: 48rem;
    ${FormElement}:first-child {
        margin-top: 0;
    }
`;
export const TextInput = styled.input`
    font-size: 1rem;
    box-shadow: rgba(0, 0, 0, 0.14) 0px 1px 2px 0px inset;
    box-sizing: border-box;
    padding: 0.75rem;
    margin-top: 0.25rem;
    // height: 2.5rem;
    width: 100%;
    border: 1px solid #cccccc;
    outline: none;
    border-radius: 0.1875rem;
    ::placeholder,
    ::-webkit-input-placeholder { 
        color: #cccccc;
    }
`;
